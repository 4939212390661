// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "20.5.0",
  "buildNumber": "11820",
  "gitSha": "1c9b12ac8aa216a2c062fbec4cba18d9cfb8b404",
  "fullVersion": "20.5.0-11820",
  "formattedVersion": "20.5.0 (11820-1c9b12ac8aa216a2c062fbec4cba18d9cfb8b404)",
  "copyrightYear": "2020"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/20.5.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/20.5.0/${stripLeadingPrefix(suffix, '#')}`
}
    
